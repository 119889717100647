import React from 'react';
import { graphql , Link  } from 'gatsby';
import { Helmet } from 'react-helmet';
import { HeaderContentfulProps } from '../components/header';
import { FooterContentfulProps } from '../components/footer';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import {
  Header,
  Footer,
  FooterBlock,
  GDPRBanner,
} from '../components';
import {Teaser, TeaserProps, FeaturedTeaser, contentTypeMap} from '../components/content-hub-items';
import cn from 'classnames';
import styles from './content-hub-listing.module.scss';
interface ContentHubListingProps {
  data: {
    contentfulContentHubListing: {
      id: string;
      header: HeaderContentfulProps;
      footer: FooterContentfulProps;
      bannerImage: {
        fluid: FluidObject | FluidObject[];
      };
      title: string;
      slug: string;
    };
    allContentfulContentHubPage: {
      nodes: TeaserProps[];
    };
    contentfulContentHubPage: TeaserProps
  };
  pageContext: {
    id: string;
    types: string[];
    currentPage: number,
    pagingItemCount: number,
    pagingTotalCount: number,
  };
  location: string;
}





const ContentHubListing: React.FC<ContentHubListingProps> = ({
  data,
  pageContext: { currentPage, pagingItemCount, pagingTotalCount, },
  location,
}: ContentHubListingProps) => {
  const pageCount = Math.ceil(pagingTotalCount / pagingItemCount);
  const pageData = data.contentfulContentHubListing;
  const contentHubItems = data.allContentfulContentHubPage.nodes;
  const featuredItem = data.contentfulContentHubPage;
  const rowCounter = [0,0];
  if(pageData) {
    return (<div className={styles.contentHubListing}>
      <Helmet>
        <title>{pageData.title || 'Reset Health'}</title>
        {/* metatags */}
      </Helmet>
      {pageData.header && <Header {...pageData.header} location={location}/>}
      <BackgroundImage className={styles.banner} fluid={pageData.bannerImage.fluid}>
          <div className={styles.inner}>
            <div className={styles.breadcrumbs}>
              <Link to="/">Home</Link>
              <Link to="/latest">Latest</Link>
            </div>
            <h1 className={styles.title}>{pageData.title}</h1>
          </div>
        </BackgroundImage>
      <div className={styles.listingSelectorContainer}>
        <span>Show me:</span>
        <ul>
          <li><Link to='/latest'>All</Link></li>
        {Object.keys(contentTypeMap).map((type, index) => {
          return <li key={`listings-link-${index}`}><Link to={`${contentTypeMap[type].breadcrumbUrl}`}>{contentTypeMap[type].breadcrumbLabel}</Link></li>;
        })}
        </ul>
      </div>
      {featuredItem && (
        <FeaturedTeaser contentType={featuredItem.contentHubItem.__typename} title={featuredItem.title.title} summary={featuredItem.summary.summary} teaserImage={featuredItem.teaserImage} slug={featuredItem.slug} />
      )}
      <div className={styles.teaserWrapper}>
      {contentHubItems.map((page) => {
        const tileHeight = (page.contentHubItem.__typename === 'ContentfulContentHubEBook' || page.contentHubItem.__typename === 'ContentfulContentHubVideo' || !page.teaserImage) ? 1 : 2;
        // const tileHeight = 1;
        const currentColumn = rowCounter[0] > rowCounter[1] ? 1 : 0;
        const currentRow = rowCounter[currentColumn];
        rowCounter[currentColumn] = rowCounter[currentColumn] + tileHeight;
        return <Teaser key={`teaser-${page.id}`} column={currentColumn} row={currentRow} tileHeight={tileHeight} {...page} />;
      })}
      </div>
      <div className={styles.paginationControlsWrapper}>
        <ul>
        {Array(pageCount).fill(undefined).map((paginationItem, index) => {
          return (
            <li className={cn(styles.paginationItem, {[styles.paginationItemActive]: currentPage === index})} key={`pagination-link-${index}`}>
              {currentPage === index && (<span>{index+1}</span>)}
              {currentPage !== index && (<Link to={`/${data.contentfulContentHubListing.slug}/${index > 0 ? index : ''}`}>{index+1}</Link>)}
            </li>
          );
        })}
        </ul>
      </div>
      {pageData.footer && (
          <Footer
            {...pageData.footer}
          />
       )}
    </div>);  
  }
  return null;
};

export default ContentHubListing;

export const pageQuery = graphql`
  query contentHubList($id: String!, $featuredItem: String, $listingsContent: [String]) {
    contentfulContentHubListing(id: {eq: $id}) {
      id
      header {
        ...HeaderFrag
      }
      footer {
        ...FooterFrag
      }
      bannerImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      title
      slug
    }
    allContentfulContentHubPage(filter: { id: {in: $listingsContent }}, sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...ContentHubTeaserFrag
      }
    }
    contentfulContentHubPage(id: {eq: $featuredItem}) {
      ...ContentHubTeaserFrag
    }
  }
`;